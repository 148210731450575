import dayjs from "dayjs";
import { defineStore } from "pinia";
import { useProfessionalStore } from "~/stores/professional";

export const STATUS = {
  BANNED: "banned",
  DELETED: "deleted",
  FREE: "free",
  FREE_FUTURE: "freeFuture",
  ACTIVE: "active",
  INACTIVE: "inactive",
  GRACE_PERIOD: "gracePeriod",
  NEVER_PAID: "neverPaid",
  REGISTERED: "registered",
  MERGED: "merged",
};

export const useAdminProfessionalStore = defineStore("adminProfessional", {
  state: () => ({
    privateData: null,
    privateDataLoading: false,
    privateDataLoaded: false,
    showAsAdmin: true,
    newPrice: null,
    scoreInfo: [],
    labels: [],
    basePrice: 0,
    latestSubPrice: null,
    price27: null,
    addingLabel: false,
    toMateLoading: false,
    professionPriceModifiers: [],
  }),
  getters: {
    futureSub: (state) => {
      const now = dayjs();
      return state.privateData?.subscriptions.filter((x) => {
        return (
          x.validFrom !== null && dayjs(x.validFrom).isAfter(now) && !x.isFree
        );
      })?.[0];
    },
    balance: (state) => {
      return state.privateData?.credit;
    },
    nextPaymentDate: (state) => {
      const activeSub = state?.activeSubscription;
      if (activeSub && !activeSub?.validUntil && activeSub?.billingCycleDate) {
        return dayjs(new Date(activeSub.billingCycleDate)).add(
          activeSub.transactionCount,
          "month"
        );
      }
      return null;
    },
    status: (state) => {
      const professionalStore = useProfessionalStore();
      const person = professionalStore.person;
      if (!person) {
        return null;
      }
      if (state.privateData?.mergedPerson) {
        return STATUS.MERGED;
      }
      if (person.banned) {
        return STATUS.BANNED;
      }
      if (person.deleted) {
        return STATUS.DELETED;
      }
      if (state.privateData?.emailConfirmationDate === null) {
        return STATUS.REGISTERED;
      }
      const activeSubscription = state.privateData?.activeSubscription;
      if (activeSubscription) {
        if (activeSubscription.isFree) {
          return STATUS.FREE;
        }
        if (
          !activeSubscription.validUntil &&
          activeSubscription.billingCycleDate
        ) {
          const nextPaymentDate = dayjs(
            new Date(activeSubscription.billingCycleDate)
          ).add(activeSubscription.transactionCount, "month");
          if (dayjs().isAfter(nextPaymentDate)) {
            return STATUS.GRACE_PERIOD;
          }
        }
        return STATUS.ACTIVE;
      }
      if (state.privateData?.futureFreeSubscription) {
        return STATUS.FREE_FUTURE;
      }
      if (!state.privateData?.lastPaidSubscription) {
        return STATUS.NEVER_PAID;
      } else {
        return STATUS.INACTIVE;
        // let isGracePeriod = this.lastPaidSubscription.validUntil
        // if (isGracePeriod) {
        //   const daysDiff = dayjs(this.lastPaidSubscription.validUntil).diff(dayjs(), 'day')
        //   isGracePeriod = daysDiff < 0 && daysDiff > -14
        // }
        // if (isGracePeriod) {
        //   return STATUS.GRACE_PERIOD
        // } else {
        //   return STATUS.INACTIVE
        // }
      }
    },
    assignedLabels: (state) => {
      if (state.privateData?.labels) {
        const labels = state.labels || [];
        return labels.filter((x) =>
          state.privateData.labels.find((y) => y.id === x.id)
        );
      } else {
        return [];
      }
    },
    notAssignedLabels: (state) => {
      if (state.privateData?.labels) {
        return state.labels.filter(
          (x) => !state.privateData.labels.find((y) => y.id === x.id)
        );
      }
      return state.labels;
    },
    newBasePrice: (state) => state.newPrice?.basePrice,
    activeSubscription: (state) => state.privateData?.activeSubscription,
    lastPaidSubscription: (state) => state.privateData?.lastPaidSubscription,
    futureFreeSubscription: (state) =>
      state.privateData?.futureFreeSubscription,
    scoreInfos: (state) => state.scoreInfo,
    statuses: () => STATUS,
    isActive() {
      return this.status === STATUS.ACTIVE;
    },
    isRegistered() {
      return this.status === STATUS.REGISTERED;
    },
    scoreInfoById: (state) => (id) =>
      state.scoreInfo?.length
        ? state.scoreInfo.find((x) => x.id === id)
        : undefined,
    groupedTransactions(state) {
      const transactions = state.privateData?.transactions;
      if (!transactions) {
        return new Map();
      }
      const transactionMap = new Map();
      for (const transaction of transactions) {
        const created = dayjs(transaction.created);
        const year = created.year();
        const month = created.month() + 1;
        const key = `${year}-${month}`;
        const transactionList = transactionMap.get(key);
        if (transactionList) {
          transactionList.push(transaction);
        } else {
          transactionMap.set(key, [transaction]);
        }
      }
      return transactionMap;
    },
  },
  actions: {
    async loadPrivateData() {
      try {
        this.privateDataLoaded = false;
        this.privateDataLoading = true;
        const professionalStore = useProfessionalStore();
        if (!professionalStore.person) {
          return;
        }
        this.loadLabels();
        this.loadScoreInfo();
        const PRIVATE_DATA_QUERY = await import(
          "./queries/personPrivateData.graphql"
        );
        const data = await this.$nuxt.$query(PRIVATE_DATA_QUERY, {
          seoName: professionalStore.person?.seoName,
        });
        this.privateData = data.personBySeoName;
        this.basePrice = data.personBySeoName?.basePrice;
        this.professionPriceModifiers =
          data.personBySeoName?.professionPriceModifiers;
        this.privateDataLoaded = true;
        const [price] = await Promise.all([
          this.loadSubPriceByVersion("2.7"),
          this.loadPriceInfo(),
          this.loadLatestSubPrice(),
        ]);
        this.price27 = price;
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "load_private_data",
          },
        });
      } finally {
        this.privateDataLoading = false;
      }
    },
    async loadBalance() {
      try {
        const professionalStore = useProfessionalStore();
        const BALANCE_QUERY = await import("./queries/balance.graphql");
        const resp = await this.$nuxt.$query(BALANCE_QUERY, {
          seoName: professionalStore.person?.seoName,
        });
        this.privateData = {
          ...this.privateData,
          ...resp.personBySeoName,
        };
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "load_balance",
          },
        });
      }
    },
    async loadPriceInfo() {
      try {
        const professionalStore = useProfessionalStore();
        // TODO shoud be part of person stuff by default
        const PRICE_INFO = await import("./queries/priceInfo.graphql");
        const resp = await this.$nuxt.$query(PRICE_INFO, {
          personId: professionalStore.person?.id,
          productId: this.activeSubscription?.product?.id,
        });
        this.newPrice = resp.priceInfo;
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "load_priceinfo",
          },
        });
      }
    },
    async loadSubPriceByVersion(version) {
      try {
        const SUB_PRICE = await import("./queries/subPrice.graphql");
        const professionalStore = useProfessionalStore();

        const professionIds = (
          this.privateData?.actualPersonProfessions ?? []
        ).map((x) => x.id);
        const miniMarkets = (this.privateData?.miniMarkets ?? []).map((m) => ({
          isTrial: m.isTrial,
          miniMarketId: m.miniMarket.id,
        }));

        const resp = await this.$nuxt.$query(SUB_PRICE, {
          cityId: professionalStore.person.city.id,
          goToHouse: professionalStore.person.goToHouse,
          goToHouseKm: professionalStore.person.goToHouseKm,
          professions: professionIds,
          miniMarkets,
          version,
        });
        return resp.subPrice;
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "load_subbypriceversion",
          },
        });
      }
    },
    async loadLatestSubPrice() {
      this.latestSubPrice = await this.loadSubPriceByVersion();
    },
    async loadLabels() {
      try {
        const LABELS_QUERY = await import("./queries/labels.graphql");
        const resp = await this.$nuxt.$query(LABELS_QUERY);
        this.labels = resp.labels;
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "load_labels",
          },
        });
      }
    },
    async loadScoreInfo() {
      try {
        const professionalStore = useProfessionalStore();
        if (!professionalStore.person?.scores?.length) {
          return;
        }
        const SCORE_INFO = await import("./queries/scoreInfo.graphql");
        const resp = await this.$nuxt.$query(SCORE_INFO, {
          scoreIds: professionalStore.person.scores.map((x) => x.id),
        });
        this.scoreInfo = resp.scoreInfo;
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "load_score_info",
          },
        });
      }
    },
    async deleteScore(scoreId) {
      try {
        const professionalStore = useProfessionalStore();
        const DELETE_SCORE = await import("./mutations/deleteScore.graphql");
        const res = await this.$nuxt.$mutate(DELETE_SCORE, {
          scoreId,
        });
        if (res.ok) {
          professionalStore.setScoreDeleted({
            id: scoreId,
            value: new Date(),
          });
        }
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "delete_score",
          },
        });
        return Promise.reject(err);
      }
    },
    async restoreScore(scoreId) {
      const RESTORE_SCORE = await import("./mutations/restoreScore.graphql");
      try {
        const professionalStore = useProfessionalStore();
        const res = await this.$nuxt.$mutate(RESTORE_SCORE, {
          scoreId,
        });
        if (res.ok) {
          professionalStore.setScoreDeleted({
            id: scoreId,
            value: null,
          });
        }
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "restore_score",
          },
        });
        return Promise.reject(err);
      }
    },
    async banPerson() {
      try {
        const BAN_PERSON = await import("./mutations/banPerson.graphql");
        const professionalStore = useProfessionalStore();
        await this.$nuxt.$mutate(BAN_PERSON, {
          id: professionalStore.person.id,
        });
        await professionalStore.reloadPerson();
        return Promise.resolve();
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.addBreadcrumb({
          category: "banPerson",
          message: this.person.id,
          level: "info",
        });
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "ban_person",
          },
        });
        return Promise.reject(err);
      }
    },
    async unbanPerson() {
      const professionalStore = useProfessionalStore();
      try {
        const UNBAN_PERSON = await import("./mutations/unbanPerson.graphql");
        await this.$nuxt.$mutate(UNBAN_PERSON, {
          id: professionalStore.person.id,
        });
        await professionalStore.reloadPerson();
        return Promise.resolve();
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.addBreadcrumb({
          category: "unbanPerson",
          message: professionalStore.person.id,
          level: "info",
        });
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "unban_person",
          },
        });
        return Promise.reject(err);
      }
    },
    async undeletePerson() {
      const professionalStore = useProfessionalStore();
      try {
        const UNDELETE_PERSON = await import(
          "./mutations/undeletePerson.graphql"
        );
        await this.$nuxt.$mutate(UNDELETE_PERSON, {
          id: professionalStore.person.id,
        });
        await professionalStore.reloadPerson();
        return Promise.resolve();
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.addBreadcrumb({
          category: "undeletePerson",
          message: professionalStore.person.id,
          level: "info",
        });
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "undelete_person",
          },
        });
        return Promise.reject(err);
      }
    },
    setBasePrice(value) {
      try {
        this.basePrice = value;
      } catch (error) {
        console.error(error);
      }
    },
    async setActiveSubBasePrice() {
      const professionalStore = useProfessionalStore();
      try {
        const SET_SUB_BASE_PRICE = await import(
          "./mutations/setSubBasePrice.graphql"
        );
        await this.$nuxt.$mutate(SET_SUB_BASE_PRICE, {
          personId: professionalStore.person.id,
        });
        await this.loadPriceInfo();
      } catch (err) {
        console.error(err);
        if (typeof err !== "string") {
          this.$nuxt.$sentry.addBreadcrumb({
            category: "setsubbaseprice",
            message: professionalStore.person.id,
            level: "info",
          });
          this.$nuxt.$sentry.captureException(err, {
            tags: {
              admin: "setsubbaseprice",
            },
          });
        }
        return Promise.reject(err);
      }
    },
    async setPersonBasePrice() {
      const professionalStore = useProfessionalStore();
      try {
        const SET_PERSON_BASE_PRICE = await import(
          "./mutations/setPersonBasePrice.graphql"
        );
        const price = parseInt(this.basePrice);
        if (isNaN(price)) {
          return Promise.reject(
            new Error(this.$nuxt.i18n.t("validations.integer"))
          );
        }
        const resp = await this.$nuxt.$mutate(SET_PERSON_BASE_PRICE, {
          personId: professionalStore.person.id,
          price,
        });

        this.privateData.basePrice = resp.basePrice;

        await this.loadPriceInfo();
      } catch (err) {
        console.error(err);
        if (typeof err !== "string") {
          this.$nuxt.$sentry.addBreadcrumb({
            category: "setbaseprice",
            message: professionalStore.person.id,
            level: "info",
          });
          this.$nuxt.$sentry.captureException(err, {
            tags: {
              admin: "setbaseprice",
            },
          });
        }
        return Promise.reject(err);
      }
    },
    async switchToLatestPricing() {
      const professionalStore = useProfessionalStore();
      try {
        const subscriptionId = this.activeSubscription?.id;
        if (!subscriptionId) {
          // eslint-disable-next-line no-throw-literal
          throw "Nincs elerheto utolso elofizetes";
        }
        const SWITCH_TO_LATEST_PRICING = await import(
          "./mutations/switchToLatestPrice.graphql"
        );
        await this.$nuxt.$mutate(SWITCH_TO_LATEST_PRICING, {
          personId: professionalStore.person.id,
          subscriptionId,
        });
        professionalStore.reloadPerson();
      } catch (err) {
        console.error(err);
        if (typeof err !== "string") {
          this.$nuxt.$sentry.addBreadcrumb({
            category: "switchToLatestPricing",
            message: professionalStore.person.id,
            level: "info",
          });
          this.$nuxt.$sentry.captureException(err, {
            tags: {
              admin: "switchToLatestPricing",
            },
          });
        }
        return Promise.reject(err);
      }
    },
    async restorePersonFromSub() {
      const RESTORE_PERSON = await import(
        "./mutations/restorePersonFromSub.graphql"
      );
      const professionalStore = useProfessionalStore();
      try {
        await this.$nuxt.$mutate(RESTORE_PERSON, {
          id: professionalStore.person.id,
        });
        await professionalStore.reloadPerson();
      } catch (err) {
        console.error(err);
        if (typeof err === "string") {
          // this.$buefy.toast.open({
          //   message: err,
          //   type: 'is-danger'
          // })
        } else {
          // this.$buefy.toast.open({
          //   message: this.$t('admin.functions.restorePersonError'),
          //   type: 'is-danger'
          // })
          this.$nuxt.$sentry.addBreadcrumb({
            category: "restorePersonFromSub",
            message: professionalStore.person.id,
            level: "info",
          });
          this.$nuxt.$sentry.captureException(err, {
            tags: {
              admin: "restore_person",
            },
          });
        }
        return Promise.reject(err);
      }
    },
    async updateSettingsFromFreeSub() {
      const UPDATE_FROM_FREE_SUB = await import(
        "./mutations/updateSettingsFromFreeSub.graphql"
      );
      const professionalStore = useProfessionalStore();
      try {
        await this.$nuxt.$mutate(UPDATE_FROM_FREE_SUB, {
          id: professionalStore.person.id,
        });
        await professionalStore.reloadPerson();
      } catch (err) {
        console.error(err);
        if (typeof err === "string") {
          this.$buefy.toast.open({
            message: err,
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            message: this.$t("admin.functions.updateSettingsError"),
            type: "is-danger",
          });
          this.$nuxt.$sentry.addBreadcrumb({
            category: "restorePersonFromSub",
            message: professionalStore.person.id,
            level: "info",
          });
          this.$nuxt.$sentry.captureException(err, {
            tags: {
              admin: "restore_person",
            },
          });
        }
      }
    },
    async addPersonLabel(labelId) {
      const professionalStore = useProfessionalStore();
      try {
        this.addingLabel = true;
        const found = this.labels.find((x) => x.id === labelId);
        if (!found) {
          throw new Error("Label not found");
        }
        const ADD_PERSON_LABEL = await import(
          "./mutations/addPersonLabel.graphql"
        );
        await this.$nuxt.$mutate(ADD_PERSON_LABEL, {
          personId: professionalStore.person.id,
          labelId,
        });
        this.privateData.labels.push(found);
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "add_person_label",
          },
        });
      } finally {
        this.addingLabel = false;
      }
    },
    async deletePersonLabel(labelId) {
      const professionalStore = useProfessionalStore();
      try {
        this.addingLabel = true;
        const found = this.privateData.labels.find((x) => x.id === labelId);
        if (!found) {
          return;
        }
        const DELETE_PERSON_LABEL = await import(
          "./mutations/deletePersonLabel.graphql"
        );
        await this.$nuxt.$mutate(DELETE_PERSON_LABEL, {
          personId: professionalStore.person.id,
          labelId,
        });
        this.privateData.labels = this.privateData.labels.filter(
          (x) => x.id !== labelId
        );
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "delete_person_label",
          },
        });
      } finally {
        this.addingLabel = false;
      }
    },
    async deletePerson(message) {
      try {
        if (!message) {
          throw new Error(this.$t("admin.delete.reasonNeeded"));
        }
        const professionalStore = useProfessionalStore();
        const ADMIN_DELETE_PERSON = await import(
          "./mutations/adminDeletePerson.graphql"
        );
        const resp = await this.$nuxt.$mutate(ADMIN_DELETE_PERSON, {
          personId: professionalStore.person.id,
          reason: message,
        });
        professionalStore.person.deleted = resp.deleted;
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "delete_person",
          },
        });
        return Promise.reject(err);
      }
    },
    async startSubscription() {
      const professionalStore = useProfessionalStore();
      try {
        const START_SUBSCRIPTION = await import(
          "./mutations/startSubscription.graphql"
        );
        await this.$nuxt.$mutate(START_SUBSCRIPTION, {
          personId: professionalStore.person.id,
        });
        await this.loadPrivateData();
      } catch (err) {
        console.error(err);
        this.$nuxt.$sentry.captureException(err, {
          tags: {
            admin: "start_subscription",
          },
        });
        return Promise.reject(err);
      }
    },
    async updatePerson(data) {
      try {
        const REDIRECT_QUERY = await import("./queries/redirect.graphql");
        const UPDATE_PERSON = await import("./mutations/updatePerson.graphql");
        const resp = await this.$nuxt.$mutate(UPDATE_PERSON, {
          ...data,
        });

        const redirect = await this.$nuxt.$query(REDIRECT_QUERY, {
          seoName: resp.seoName,
        });
        const professionalStore = useProfessionalStore();
        if (redirect.redirect && redirect.redirect !== resp.seoName) {
          await professionalStore.reloadPerson();
        }
        professionalStore.person = {
          ...professionalStore.person,
          memo: resp.memo,
          name: resp.name,
          seoName: resp.seoName,
          goToHouse: resp.goToHouse,
          goToHouseKm: resp.goToHouseKm,
          city: resp.city,
        };
        this.privateData = {
          ...this.privateData,
          ...resp,
        };
      } catch (error) {
        console.error(error);
        this.$nuxt.$sentry.captureException(error, {
          tags: {
            admin: "edit",
          },
        });
        return Promise.reject(error);
      }
    },
    async createTransfer(data) {
      try {
        const createTransferMutation = await import(
          "./mutations/createTransfer.graphql"
        );
        const resp = await this.$nuxt.$mutate(createTransferMutation, data);
        console.info("createTransfer -> resp", resp);
      } catch (error) {
        console.error(error);
        this.$nuxt.$sentry.captureException(error, {
          tags: {
            admin: "createTransfer",
          },
        });
        return Promise.reject(error);
      }
    },
    setShowAsAdmin(value) {
      this.showAsAdmin = value;
    },
    setLabels(value) {
      this.labels = value;
      this.labels.sort((a, b) => {
        return a.description.localeCompare(b.description);
      });
    },
    replaceTransaction(transaction) {
      this.privateData.transactions = this.privateData.transactions.map((x) => {
        if (x.id === transaction.id) {
          return transaction;
        }
        return x;
      });
    },
    async toMate() {
      try {
        this.toMateLoading = true;
        const professionalStore = useProfessionalStore();
        const TO_MATE = await import("./mutations/toMate.graphql");
        const resp = await this.$nuxt.$mutate(TO_MATE, {
          personId: professionalStore.person.id,
        });
        return Promise.resolve(resp.tenderIdForOperatorPage);
      } catch (error) {
        console.error("adminProfessional toMate", error);
        this.$nuxt.$sentry.captureException(error, {
          tags: {
            admin: "toMate",
          },
        });
        return Promise.reject(error);
      } finally {
        this.toMateLoading = false;
      }
    },
  },
});
